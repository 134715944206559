export default function Tawk() {
    const script_content = `
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/66c6dedc50c10f7a009f2143/1i5sdd39d';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
        })();        
    `;
    return (
        <script dangerouslySetInnerHTML={{ __html: script_content }} type="text/javascript"></script>
    );
}